export default defineNuxtRouteMiddleware(async (to) => {
  // hotfix for nuxt generate running this code
  if (import.meta.server) {
    return;
  }

  const localePath = useLocalePath();
  const layoutStore = useLayoutStore();
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  if (!user.value.AgreedGallerySecTradeTerms) {
    layoutStore.setAfterLoginPage({ page: to });

    return await navigateTo(localePath('dashboard-advertising-space-terms'));
  }
});
